// https://github.com/glennflanagan/react-collapsible
import React from 'react'
import Collapsible from 'react-collapsible'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import ContentHero from '../components/ContentHero'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  Counter: {
    ul: {
      paddingLeft: '20px',
      listItemStyle: 'disc',
    },
  },
})

const App = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout>
      <Hero
        title="Mitgestalter"
        subtitle="Werte zu schaffen ist unsere Leidenschaft"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="FAQ - Antworten auf häufig gestellte Fragen"
          subtitle="Egal ob Praktikant oder Berufserfahrener / Professional – in Sachen Bewerbung ist es gut, wenn keine Fragen offen bleiben. Hier findest Du häufig gestellte Fragen und unsere Antworten zu Deinem Einstieg bei colenio. Sollte die Antwort auf Deine Frage nicht dabei sein, sind wir Deine Ansprechpartner und helfen Dir gerne weiter."
        />

        <div className="container is-fluid">
          <Collapsible
            className="title"
            trigger="+ Wie bewerbe ich mich am besten?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Wenn Du Dich bei uns bewerben möchtest, dann kannst du uns eine
              Email an <a href="mailto:jobs@colenio.de">jobs@colenio.de</a>{' '}
              senden.
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wird ein Anschreiben benötigt?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Ein Anschreiben ist keine Pflicht, zeigt uns aber deine
              Motivation. Achte auf Angabe der Stellenbezeichnung, der Quelle
              und des Datums.
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Was gibt es bei der Bewerbung zu beachten?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Richte dein Anschreiben an den in der Stellenanzeige genannten
              Ansprechpartner (wichtig: korrekte Schreibweise seines Namens und
              des Firmennamens). Formuliere Dein Anschreiben zielgerichtet:
              <br />
              Liefer uns alle wichtigen Informationen, in Bezug auf die vakante
              Position und auf die im Jobangebot geforderten Punkte (z. B.
              Angabe der Gehaltsvorstellungen und des frühesten
              Eintrittstermin). <br />
              Mache deutlich, warum Du sich für die Stelle interessierst und was
              Dich dafür qualifiziert. <br />
              Achte auf die korrekte Form (keine Fehler, nicht länger als eine
              Seite, nicht kürzer als eine halbe Seite).
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className={'title ' + classes.Counter}
            trigger="+ Wen sucht Ihr genau?"
            triggerOpenedClassName="title"
            easing="cubic-bezier(0.175, 0.885, 0.32, 2.275)"
          >
            <br />
            <p>
              <strong>Du passt gut zu colenio, wenn...</strong>
              <ul>
                <li>
                  Du Motivation, Gestaltungswillen und Leidenschaft für das
                  mitbringst, was du tust und Freude daran hast, echte Probleme
                  zu lösen
                </li>
                <li>
                  Du ein äußerst hohes Maß an Freiheit, Selbstorganisation und
                  flachen Hierarchien schätzt. Das nutzt du, um dich zu
                  entfalten und fachlich sowie persönlich weiterzuentwickeln.
                  Enge Führung und Vorgaben findest du bei uns nicht.
                </li>
                <li>
                  Du nicht nur qualitativ hochwertige Software entwickeln
                  willst, sondern auch, dass die Kunden einen echten Mehrwert
                  daraus ziehen und sie anwenden.
                </li>
                <li>
                  Ehrlichkeit und Authentizität Dir wichtig sind. Du vertrittst
                  deine Meinung daher auch diplomatisch und selbstbewusst.
                </li>
                <li>
                  Du anderen gerne eine helfende Hand gibst, Dich aber nicht
                  scheust, auch selbst um Hilfe zu bitten.
                </li>
              </ul>
              <br />
              Für die einzelnen Bereiche suchen wir Kollegen mit folgenden
              Qualifikationen:
              <br />
              <br />
              <strong>Für den Bereich Datenschutz & Compliance</strong>
              <ul>
                <li>
                  Volljurist/-in oder Diplom-Jurist/-in im Master-Studiengang,
                  1. Staatsexamen
                </li>
                <li>
                  Spass an IT-Themen und Compliance, insbesondere
                  Datenschutzrecht
                </li>
                <li>
                  Beratergen, d.h. Interesse an immer wieder neuen Themen und
                  Projekten, Fortbildung anhand neuester Entwicklungen
                </li>
                <li>
                  Beratergen, d.h. Interesse an immer wieder neuen Themen und
                  Projekten, Fortbildung anhand neuester Entwicklungen
                </li>
              </ul>
              <br />
              <strong>Für den Bereich (Software) Entwicklung:</strong>
              <ul>
                <li>
                  Abgeschlossenes Studium im Bereich Informatik
                  (Wirtschaftsinformatik, Nachrichtentechnik oder IT-Sicherheit)
                </li>
                <li>
                  Alternativ eine abgeschlossene Ausbildung zum
                  Fachinformatiker/-in mit entsprechender Berufserfahrung
                </li>
                <li>
                  Interesse und Begeisterung für neue Trends und Technologien im
                  IT Sec. Bereich
                </li>
              </ul>
              <br />
              <strong>Für den Bereich Maschinen- und Anlagenbau:</strong>
              <ul>
                <li>
                  Abgeschlossenes Studium der Fachrichtung
                  Wirtschaftsingenieurwesen, Bauingenieurwesen, Maschinenbau,
                  Verfahrenstechnik, Betriebswirtschaftslehre mit technischem
                  Bezug
                </li>
                <li>
                  Alternativ eine Ausbildung als staatlich geprüfte/-r
                  Techniker/-in
                </li>
              </ul>
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wie sieht der Bewerbungsprozess aus?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Es findet in der Regel zuerst ein Telefoninterview statt. Im
              Anschluss findet ein Videointerview und zuletzt noch ein
              persönliches Vorstellungsgespräch statt. Bei Entwicklern wird
              statt dem klassischen Vorstellungsgespräch eine Coding Challenge
              durchgeführt.
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wer nimmt an den Gesprächen teil?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Bei jedem Gespräch sind je nach Stellenschwerpunkt entsprechende
              Kollegen aus den Teams dabei, ein Vertreter aus Human Resources
              und je nach Gespräch auch ein Geschäftsführer. Eine Entscheidung
              wird immer gemeinsam getroffen.
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Welche Personalauswahlverfahren werden genutzt?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              <ul>
                <li>Telefon- oder Videointerview</li>
                <li>Vorstellungsgespräch</li>
                <li>Coding-Challenge (für Entwickler)</li>
              </ul>
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wie sieht das Onboarding aus?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Bereits vor deinem ersten Arbeitstag erhälst du eine Mail mit
              allen benötigten Informationen für einen guten Start bei uns. Ein
              Vertreter aus Human Resources wird deine ersten Schritte bei
              colenio begleiten und dir helfen, dich innerhalb des Unternehmens
              zu vernetzen und unsere Kultur und unseren Spirit kennenzulernen.
              <br />
              <br />
              In regelmäßigen Terminen? kannst du dich in deiner Anfangszeit
              aktiv mit deinen Kollegen austauschen und Kontakte knüpfen. Mit
              deinem Vorgesetzten besprichst du deine ersten Themen, damit du
              dich mit deinen neuen Aufgaben vertraut machen kannst.
              <br />
              <br />
              Für deine weitere persönliche und fachliche Entwicklung kannst du
              jederzeit auf deine Kolleg*innen zurückgreifen, die dir mit ihrer
              Erfahrung hilfreiche Tipps geben werden. Um dich bestmöglich auf
              deine neuen Aufgaben vorzubereiten, erhältst du eine Top
              Aussattung deiner Wahl.
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Welche Hardware bekomme ich?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Ob MacBook, Windows oder Linux, ob iPhone oder Android, wir sparen
              nicht an der technischen Ausstattung, denn Produktivität und der
              Feelgood-Faktor sind uns sehr wichtig. Du bekommst von uns alles
              was du benötigst um dich wohl zu fühlen. Dazu gehört ein Notebook,
              ein Headset, ein Handy und falls notwendig auch ein Monitor sowie
              eine Tastatur und eine Maus.{' '}
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wie wird entwickelt?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Jeder Kunde ist anders? Unsere Kollegen wählen die für ihr Projekt
              passende Arbeitsweise selbst aus. Ob XXX oder XXX. Jedes Team
              entscheidet eigenverantwortlich, welche Maßnahmen sie treffen, um
              die gewünschte Qualität und Zufriedenheit beim Kunden zu erzeugen.
              Schließlich möchten wir einen Mehrwert für unsere Kunden schaffen
              und erfolgreich sein.
            </p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ Welcher Tech Stack wird verwendet?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              <ul>
                <li>Azure, AWS</li>
                <li>GitHub (Actions), Azure DevOps, GitLab CI</li>
                <li>Terraform, Pulumi, Crossplane, CDK, FluxCD, ArgoCD</li>
                <li>Containers, Helm, Kubernetes</li>
                <li>Kotlin/Java, Gradle, Spring, C#/.NET, Go, Rust</li>
                <li>TypeScript, Angular, React, Svelte, Vue.js</li>
                <li>Cypress, SonarQube</li>
                <li>Kafka, neo4j, MongoDB, PostgresQL</li>
                <li>Elastic Stack, Prometheus, Grafana</li>
              </ul>
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wer sind die Kunden?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              <a href="https://colenio.de/customers">Kunden</a>
            </p>
            <br />
          </Collapsible>
          <Collapsible
            className="title"
            trigger="+ Wie hoch ist der Reiseanteil?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Wir sind zwar Berater, aber wir arbeiten zu 90 % Remote.
              Kundenbesuche gibt es selten und falls doch, dann handelt es sich
              um Tageseinsätze.
            </p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ Kann ich mich auf mehrere Stellen gleichzeitig bewerben?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Eine Bewerbung reicht aus. Bewirb Dich einfach auf Deine
              Lieblingsstelle. Im Anschreiben oder in der Betreffzeile kannst Du
              gerne alle weiteren für Dich interessanten Stellen auflisten und
              entsprechend priorisieren.
            </p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ Werden meine Unterlagen auf mehrere Stellen geprüft oder Alternativen erwogen?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Eine Bewerbung reicht aus. Wenn Du dich im Anschreiben oder in der
              Betreffzeile auf mehrere Stellen beziehst, berücksichtigen wir
              diese ebenfalls.
            </p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ Ist eine Initiativ­bewerbung möglich?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>
              Falls bei unseren ausgeschriebenen Stellen nichts für Dich dabei
              war, kannst Du dich gerne initiativ bewerben. Bitte beschreibe
              Deinen Wunschbereich so genau wie möglich und nenne uns den für
              Dich realisierbaren Einstiegszeitraum an. Sobald sich bei uns
              neuer Bedarf ergibt, kommen wir auf Dich zu. Dabei kann etwas Zeit
              vergehen, bitte hab dafür Geduld.
            </p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ Wie lange werden meine Daten gespeichert?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>Wir berücksichtigen hier die Vorgaben der DSGVO.</p>
            <br />
          </Collapsible>

          <Collapsible
            className="title"
            trigger="+ An wen kann ich mich bei Fragen wenden?"
            triggerOpenedClassName="title"
            easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
          >
            <br />
            <p>Matthias Adrian | Managing Director</p>
            <p>
              0152 587 390 54 |{' '}
              <a href="mailto:jobs@colenio.de"> jobs@colenio.de</a> |{' '}
              <a href="https://colenio.de">colenio.de</a>
            </p>
            <br />
          </Collapsible>
        </div>
      </div>
    </Layout>
  )
}

export default App

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
